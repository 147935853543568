import { QoreClient } from "@feedloop/qore-client";
import createQoreContext from "@feedloop/qore-react";

const client = new QoreClient({
  endpoint: process.env.GATSBY_QORE_ENDPOINT || "",
  projectId: process.env.GATSBY_QORE_PROJECT_ID || "",
  organizationId: process.env.GATSBY_QORE_ORG_ID || "",
  authenticationId: process.env.GATSBY_QORE_AUTH_ID || "",
  onError: error => {
    if (error?.response?.status === 401 && window.location.pathname !== "/login") {
      window.location.replace("/login");
    }
  }
});
const qore = createQoreContext(client);

export default qore;

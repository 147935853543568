import * as React from "react";
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  Link,
  VisuallyHidden,
  chakra,
  useColorModeValue,
  Center,
  Icon,
  Grid,
  GridItem,
  InputGroup,
  InputRightAddon,
  Input,
  Button,
  Image,
  useToast,
  useBoolean
} from "@chakra-ui/react";
import {
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaFacebook,
  FaEnvelope,
  FaWhatsapp
} from "react-icons/fa";
import ShopeeSrc from "../images/shopee.png";
import TokopediaSrc from "../images/tokopedia.png";
import LazadaSrc from "../images/lazada.png";
import BlibliSrc from "../images/blibli.png";
import TiktokSrc from "../images/tiktok.png";
import WhatsappSrc from "../images/whatsapp.png";
import TokoSrc from "../images/toko.png";
import qore from "../context/qoreContext";
import { navigate } from "gatsby";

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"bold"} fontSize={"lg"} my={2}>
      {children}
    </Text>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target="_blank"
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: "grey"
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function LargeWithAppLinksAndSocial() {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = useBoolean(false);
  const toast = useToast();
  const { insertRow } = qore.views.allSubscriber.useInsertRow();

  const handleChange = e => {
    setEmail(e.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setLoading.on();
      const emailInserted = await insertRow({ email });
      if (!emailInserted) throw false;
      toast({
        position: "top",
        duration: "4000",
        render: () => (
          <Box p={3} borderRadius="md" bg="whitesmoke">
            <Center>Thank you for subscribing</Center>
          </Box>
        )
      });
      setEmail("");
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Please try again later",
        status: "error",
        position: "top",
        isClosable: true
      });
    } finally {
      setLoading.off();
    }
  };

  const SubscribeButton = () => {
    return (
      <Button isLoading={loading} type="submit" size="sm" bg="#353A3F" colorScheme="blackAlpha">
        <Text fontSize={{ base: "14px", md: "14px", lg: "16px" }}>Sign Up</Text>
      </Button>
    );
  };

  return (
    <Box bg={"black"} color={"white"}>
      <Container as={Stack} maxW="container.xl" py={10}>
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ md: 1, lg: 8 }}>
          <Stack align={"flex-start"}>
            <ListHeader>Contact Us</ListHeader>
            <Center>
              <Icon as={FaWhatsapp} boxSize={5} mr={3} />
              <Link
                href="https://wa.me/6285866399370"
                fontSize={{ base: "14px", md: "14px", lg: "18px" }}
                target="_blank">
                +62 858 6639 9370
              </Link>
            </Center>
            <Center>
              <Icon as={FaEnvelope} boxSize={5} mr={3} />
              <Link
                href={"mailto:hello@itsomgbeauty.com"}
                fontSize={{ base: "14px", md: "14px", lg: "18px" }}>
                hello@itsomgbeauty.com
              </Link>
            </Center>
          </Stack>

          <GridItem py={{ base: 4, md: 0 }} colSpan={{ md: 2 }} px={{ md: 8 }}>
            <Stack align={"flex-start"}>
              <ListHeader>Shop</ListHeader>
              <Box bg="white" rounded="xl" p={4} width={"100%"}>
                <SimpleGrid columns={{ base: 3, md: 4 }} spacing={4} textAlign="center">
                  <a href="https://shopee.co.id/omg_official" target="_blank" rel="noreferrer">
                    <Image
                      style={{ margin: "auto", display: "block" }}
                      src={ShopeeSrc}
                      alt="shopee"
                    />
                  </a>
                  <a href="https://www.tokopedia.com/omgofficial" target="_blank" rel="noreferrer">
                    <Image
                      style={{ margin: "auto", display: "block" }}
                      src={TokopediaSrc}
                      alt="tokopedia"
                    />
                  </a>
                  <a href="https://wa.me/6285866399370" target="_blank" rel="noreferrer">
                    <Image
                      style={{ margin: "auto", display: "block" }}
                      src={WhatsappSrc}
                      alt="whatsapp"
                    />
                  </a>
                  <a href="https://www.tiktok.com/@itsomgbeauty" target="_blank" rel="noreferrer">
                    <Image
                      style={{ margin: "auto", display: "block" }}
                      src={TiktokSrc}
                      alt="tiktok"
                    />
                  </a>
                  <a
                    href="https://www.lazada.co.id/omg-oh-my-glam/?from=wangpu&lang=id&langFlag=id&page=1&pageTypeId=2&q=All-Products&sort=priceasc"
                    target="_blank"
                    rel="noreferrer">
                    <Image
                      style={{ margin: "auto", display: "block" }}
                      src={LazadaSrc}
                      alt="lazada"
                    />
                  </a>
                  <a
                    href="https://www.blibli.com/merchant/omg-official-store/OHM-60023?excludeProductList=false&promoTab=false"
                    target="_blank"
                    rel="noreferrer">
                    <Image
                      style={{ margin: "auto", display: "block" }}
                      src={BlibliSrc}
                      alt="blibli"
                    />
                  </a>
                  <a href="https://linktr.ee/tokokosmetikomg" target="_blank" rel="noreferrer">
                    <Image style={{ margin: "auto", display: "block" }} src={TokoSrc} alt="toko" />
                  </a>
                </SimpleGrid>
              </Box>
            </Stack>
          </GridItem>

          <Stack align={"flex-start"}>
            <ListHeader>Subscribe</ListHeader>
            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
              <InputGroup size={{ md: "sm", lg: "md" }} pb={2}>
                <Input
                  bg="white"
                  roundedStart="md"
                  pl={2}
                  size={{ md: "sm", lg: "md" }}
                  onChange={handleChange}
                  value={email}
                  color="black"
                  placeholder="Email"
                  type="email"
                  required
                />
                <InputRightAddon bg="black" px={0} roundedEnd="md" children={<SubscribeButton />} />
              </InputGroup>
            </form>
            <Grid w="100%" templateColumns="repeat(4, 1fr)" textAlign="center">
              <GridItem colSpan={1}>
                <SocialButton label={"Instagram"} href={"https://www.instagram.com/itsomgbeauty/"}>
                  <FaInstagram size={20} />
                </SocialButton>
              </GridItem>
              <GridItem colSpan={1}>
                <SocialButton label={"Facebook"} href={"https://www.facebook.com/itsomgbeauty/"}>
                  <FaFacebook size={20} />
                </SocialButton>
              </GridItem>
              <GridItem colSpan={1}>
                <SocialButton label={"Twitter"} href={"https://twitter.com/itsomgbeauty"}>
                  <FaTwitter size={20} />
                </SocialButton>
              </GridItem>
              <GridItem colSpan={1}>
                <SocialButton
                  label={"YouTube"}
                  href={"https://www.youtube.com/channel/UCZFcQcKgiSdDnuOrE4s1dfQ"}>
                  <FaYoutube size={20} />
                </SocialButton>
              </GridItem>
            </Grid>
          </Stack>
        </SimpleGrid>
      </Container>
      <Container maxW="container.xl">
        <Center borderTopWidth={0.5} borderStyle={"solid"} borderColor={"white"} py={8}>
          <SimpleGrid
            fontSize={{ base: "14px", md: "16px", lg: "18px" }}
            columns={{ base: 1, md: 2 }}
            spacing={{ base: 2, md: 4 }}
            textAlign="center">
            <Text
              onClick={() => navigate("/privacy-policy")}
              _hover={{ textDecoration: "underline", cursor: "pointer" }}>
              Privacy Policy
            </Text>
            <Text> © OMG beauty {new Date().getFullYear()}</Text>
          </SimpleGrid>
        </Center>
      </Container>
    </Box>
  );
}

import * as React from "react";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  Stack,
  Container,
  Center,
  Image
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import LogoSrc from "../images/logo-omg.png";
import { navigate, Link } from "gatsby";
import styled from "styled-components";

const Links = [
  {
    label: "BEAUTY TIPS",
    url: "/beauty-tips"
  },
  {
    label: "BEAUTY QUIZ",
    url: "/beauty-quiz"
  },
  {
    label: "ABOUT US",
    url: "/about-us"
  }
];

const HeaderLink = styled(Link)`
  color: white;
  :hover {
    text-decoration: underline;
  }
`;

const activeStyles = {
  textDecoration: "underline",
  fontWeight: "bold"
};

const NavLink = ({ children, url }) => (
  <HeaderLink to={url} activeStyle={activeStyles}>
    {children}
  </HeaderLink>
);

const CategoryLink = styled(Link)`
  :hover {
    text-decoration: underline;
  }
`;

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box bg={"black"} position="fixed" w="100%" zIndex="9">
        <Container maxW="container.xl">
          <>
            <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
              <Box
                onClick={() => {
                  navigate("/");
                }}>
                <Image style={{ margin: "auto", display: "block" }} src={LogoSrc} alt="logo-omg" />
              </Box>
              <Flex alignItems={"center"}>
                <IconButton
                  size={"md"}
                  variant="outline"
                  color="white"
                  borderColor="black"
                  colorScheme="blackAlpha"
                  _focus={{ boxShadow: "none" }}
                  icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                  aria-label={"Open Menu"}
                  display={{ md: "none" }}
                  onClick={isOpen ? onClose : onOpen}
                />
                <HStack
                  as={"nav"}
                  spacing={{ md: 8, lg: 10, xl: 12 }}
                  display={{ base: "none", md: "flex" }}>
                  {Links.map(link => (
                    <NavLink url={link.url} key={link.url}>
                      <Center
                        fontSize={{ base: "16px", md: "18px", lg: "20px" }}
                        letterSpacing={"2px"}>
                        {link.label}
                      </Center>
                    </NavLink>
                  ))}
                </HStack>
              </Flex>
            </Flex>
          </>
        </Container>
        <>
          <Flex>
            <Box bg={"#991C21"} color="white" py={3} w="50%">
              <CategoryLink to={"/make-up"} activeStyle={activeStyles}>
                <Center
                  fontSize={{ base: "14px", md: "18px" }}
                  letterSpacing={"2px"}
                  fontWeight="600"
                  py={0}>
                  MAKE UP
                </Center>
              </CategoryLink>
            </Box>
            <Box bg={"#FBCCC3"} color="black" py={3} w="50%">
              <CategoryLink to={"/skincare"} activeStyle={activeStyles}>
                <Center
                  fontSize={{ base: "14px", md: "18px" }}
                  letterSpacing={"2px"}
                  fontWeight="600">
                  SKIN CARE
                </Center>
              </CategoryLink>
            </Box>
          </Flex>
          {isOpen ? (
            <Box pb={4} mt={4} display={{ md: "none" }}>
              <Stack as={"nav"} spacing={4}>
                {Links.map(link => (
                  <NavLink url={link.url} key={link.url}>
                    <Center fontSize={{ base: "14px", md: "18px" }} letterSpacing={"2px"}>
                      {link.label}
                    </Center>
                  </NavLink>
                ))}
              </Stack>
            </Box>
          ) : null}
        </>
      </Box>
    </>
  );
}
